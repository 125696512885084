// import axios from 'axios';
import React, { useEffect, useState } from "react";
// import { useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";
// import * as XLSX from "xlsx";
// import Images from '../../asset';
import { useTable } from "react-table";
// import ReactPaginate from "react-paginate";
export default function ViewEmployeeTable() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();
  const columns = React.useMemo(
    () => [
      { Header: "Sno", accessor: "sno" },
      { Header: "Employee Id", accessor: "employeeId" },
      { Header: "Empolyee Name", accessor: "employeeName" },
      { Header: "Role", accessor: "role" },
      { Header: "Description", accessor: "description" },
      { Header: "Mobile Number", accessor: "mobileNumber" },
      { Header: "Alter Number", accessor: "alterNumber" },
      { Header: "EMail ID", accessor: "emailId" },
      { Header: "Aadhar Number", accessor: "aadharNumber" },
      { Header: "Address", accessor: "address" },
      { Header: "City", accessor: "city" },
      { Header: "Pincode", accessor: "pincode" },
      { Header: "Bank Name", accessor: "bankName" },
      { Header: "BankAccount Number", accessor: "bankaccountNumber" },
      { Header: "IFSC Code", accessor: "ifscCode" },
      { Header: "Date Of Joining", accessor: "dateOfJoining" },
      { Header: "Date Of Reveling", accessor: "dateOfReveling" },
      { Header: "Status", accessor: "status" },
      

    ],
    []
  );

  const datas = [
    {
      sno: 1,
      employeeId: "EMP001",
      employeeName: "Prasanth",
      role: "Trannier",
      description: "ui/ux desinger",
      age: 25,
      mobileNumber: "1234567890",
      alterNumber: "9941466760",
      emailId: "prasanthap@gmail.com",
      aadharNumber: "2313 4443 5789",
      address: "abc street chennai",
      city:  " chennai",
      pincode:'603110',
      bankName:'indian bank',
      bankaccountNumber:'973498237498',
      ifscCode:'IBS000',
      dateOfJoining:'12.09.2024',
      dateOfReveling:'null',
      status:'working',



      // editId: <img src={Images.pencilIcon}/>
    },
  ];
  const [currentPages, setCurrentPages] = useState(0);
  const itemsPerPages = 9;
  const pageCount = Math.ceil(datas.length / itemsPerPages);

  const handlePageClick = (event) => {
    setCurrentPages(event.selected);
  };

  const currentData = datas.slice(
    currentPages * itemsPerPages,
    (currentPages + 1) * itemsPerPages
  );

  //   const tableInstance = useTable({ columns, data: datas });

  //   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  //     tableInstance;
  return (
    <>
    <div className="viewtable">
      <div className="searchbar">
        <input type="text" placeholder="search" className="search"/>
      </div>
      <div className="emptable">
    <table className="empdetailstable"  cellPadding='10px' >
      <thead>
        <tr>
        <th className="tableheader"> S.NO</th>
          <th className="tableheader">  EmployeeId</th>
          <th  className="tableheader"> EmployeeName</th>
          <th className="tableheader"> Roles</th>
          <th className="tableheader"> Description</th>
          <th className="tableheader"> MobileNumber</th>
          <th className="tableheader">AlternateNumber</th>
          <th className="tableheader"> Description</th>
          <th className="tableheader"> EmailId</th>
          <th className="tableheader" >AadharNumber</th>
          <th className="tableheader">Address</th>

          <th className="tableheader">city</th>
          <th className="tableheader">Pincode</th>
          <th className="tableheader">bankName</th>
          <th className="tableheader">bankaccountNumber</th>
          <th className="tableheader">IFSCCode</th>
          <th className="tableheader">DateOfJoining</th>
          <th className="tableheader">DateOfReveling</th>
          <th className="tableheader">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td className="tabledata">01</td>
          <td className="tabledata">
            2k01
          </td>
          <td className="tabledata">Keerthika</td>
          <td className="tabledata">Develper</td>
          <td className="tabledata">backend</td>
          <td className="tabledata">1234567890</td>
          <td className="tabledata">1234567890 </td>
          <td className="tabledata">Develper </td>
          <td className="tabledata">keerthika2kvw@gmail.com </td>
          <td className="tabledata">1234567890 </td>
          <td className="tabledata">abcstreettpr </td>
          <td className="tabledata">chennai</td>
          <td className="tabledata">603110 </td>
          <td className="tabledata">Statebankofindia </td>
          <td className="tabledata">12324234 </td>
          <td className="tabledata">abjs1234 </td>
          <td className="tabledata">10-10-2024 </td>
          <td className="tabledata">Null </td>
          <td className="tabledata"> working</td>
        </tr>
        <tr>
        <td className="tabledata">01</td>
          <td className="tabledata">
            2k02
          </td>
          <td className="tabledata">Deepa</td>
          <td className="tabledata">Develper</td>
          <td className="tabledata">backend</td>
          <td className="tabledata">1234567890</td>
          <td className="tabledata">1234567890 </td>
          <td className="tabledata">Develper </td>
          <td className="tabledata">Deepa2kvw@gmail.com </td>
          <td className="tabledata">1234567890 </td>
          <td className="tabledata">abcstreettpr </td>
          <td className="tabledata">chennai</td>
          <td className="tabledata">603110 </td>
          <td className="tabledata">Statebankofindia </td>
          <td className="tabledata">12324234 </td>
          <td className="tabledata">abjs1234 </td>
          <td className="tabledata">10-10-2024 </td>
          <td className="tabledata">Null </td>
          <td className="tabledata"> working</td>
        </tr>
        <tr>
        <td className="tabledata">01</td>
          <td className="tabledata">
            2k03
          </td>
          <td className="tabledata">Maha</td>
          <td className="tabledata">Develper</td>
          <td className="tabledata">backend</td>
          <td className="tabledata">1234567890</td>
          <td className="tabledata">1234567890 </td>
          <td className="tabledata">Develper </td>
          <td className="tabledata">Maha2kvw@gmail.com </td>
          <td className="tabledata">1234567890 </td>
          <td className="tabledata">abcstreettpr </td>
          <td className="tabledata">chennai</td>
          <td className="tabledata">603110 </td>
          <td className="tabledata">Statebankofindia </td>
          <td className="tabledata">12324234 </td>
          <td className="tabledata">abjs1234 </td>
          <td className="tabledata">10-10-2024 </td>
          <td className="tabledata">Null </td>
          <td className="tabledata"> working</td>
        </tr>
        <tr>
        <td className="tabledata">01</td>
          <td className="tabledata">
            2k04
          </td>
          <td className="tabledata">Anu</td>
          <td className="tabledata">Develper</td>
          <td className="tabledata">backend</td>
          <td className="tabledata">1234567890</td>
          <td className="tabledata">1234567890 </td>
          <td className="tabledata">Develper </td>
          <td className="tabledata">Anu2kvw@gmail.com </td>
          <td className="tabledata">1234567890 </td>
          <td className="tabledata">abcstreettpr </td>
          <td className="tabledata">chennai</td>
          <td className="tabledata">603110 </td>
          <td className="tabledata">Statebankofindia </td>
          <td className="tabledata">12324234 </td>
          <td className="tabledata">abjs1234 </td>
          <td className="tabledata">10-10-2024 </td>
          <td className="tabledata">Null </td>
          <td className="tabledata"> working</td>
        </tr>
      </tbody>
    </table>
    </div>
    </div>
     {/* <div className="headerTimeLine px-3 py-3">
        <div
          className="px-5 d-flex"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="d-flex search-block"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <input
              type="search"
              className="table-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="image_icon"> */}
              {/* <img
                  src={Images.searchIcon}
                  style={{ width: "100%", height: "100%" }}
                  alt="Search"
                /> */}
            {/* </div>
          </div>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ width: "50%" }}
          ></div>
        </div>
      </div>
      <div className="table_form_page">
        <div className="table-wrapper"> */}
          {/* <div className="table_ss"> */}
            {/* <table {...getTableProps()} className="table">
              <thead style={{ background: "#002147", border: "none" }}>
                {headerGroups.map((headerGroup) => (
                  <tr
                    style={{ background: "#002147", borderRadius: 10 }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        style={{
                          border: "none",
                          background: "#002147",
                          color: "#fff",
                        }}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr style={{ border: "none" }} {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          style={{
                            border: "none",
                            borderBottom: "2px solid #000",
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          {/* </div>
        </div> */}
        {/* <div className="pagination-wrapper">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
      />
        </div> */}
      {/* </div> */}

      {/* Pagination */}
      {/* <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        // pageCount={pageCount}
        // onPageChange={handlePageClick}
        containerClassName={"pagination"}
        activeClassName={"active"}
      /> */}
    </>
  );
}
