
import React from 'react'
import Sidebar from '../Common/component/Sidebar'
import Navbar from '../Common/component/Navbar'
import NewQuotationForm from '../Common/component/NewQuotationForm/NewQuotationForm'

export default function NewQuotation() {
  return (
    <section>
    <div className="container-fluid" style={{overflow:"hidden"}}>
      <div className="row p-0">
        <div className="col-lg-2 p-0">
      
          <Sidebar/>
        </div>
        <div className="col-lg-10  p-0">
          <div className="row ">
            <div className="col-lg-12 p-0">
              <Navbar/>

              
            </div>
            <div className="col-lg-12 ">
                <NewQuotationForm/>
                {/* <EmployecreationForm/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
