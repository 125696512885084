import React from "react";
import Sidebar from "../Common/component/Sidebar";
import Navbar from "../Common/component/Navbar";
import EmployecreationForm from "../Common/component/EmployecreationForm/EmployecreationForm";
// import AddSales from "../Common/component/AddSales/AddSales";

export default function Employeecreation() {
  return (
    <>
      <section>
        <div className="container-fluid" style={{overflow:"hidden",height:'100vh'}}>
          <div className="row p-0">
            <div className="col-lg-2 p-0">
          
              <Sidebar/>
            </div>
            <div className="col-lg-10  p-0">
              <div className="row ">
                <div className="col-lg-12 p-0">
                  <Navbar/>

                  
                </div>
                <div className="col-lg-12 ">
                    {/* <EmployecreationForm/> */}
                    {/* <AddSales/> */}
                     <EmployecreationForm/> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
