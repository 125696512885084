import React from 'react'
import Images from '../../asstes'

export default function Navbar() {
  return (
    <>
      <div className="d-flex  py-3 justify-content-end ml-3" style={{backgroundColor:" #FFF6EA"
    }} >
        <div style={{paddingRight:40,display:"flex",flexDirection:"row",gap:15}}>

      <img src={Images.notificationIcon} style={{width:'100%',cursor:'pointer'}}/>  
      <img src={Images.logoutIcon} style={{width:'100%',cursor:'pointer'}}/>  
        </div>

      </div>
    {/* <nav class="navbar navbar-expand-sm bg-light justify-content-end" style={{ height: "75px", width: "1440px" }}> */}
  {/* <ul class="navbar-nav">
    <li class="nav-item">
    
    
    </li>
    </ul> */}
      {/* </nav> */}
      </>
  )
}
