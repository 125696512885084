import React from "react";
import Images from "../../asstes";
import { useNavigate } from "react-router";

export default function LoginForm() {
  const navigation=useNavigate()
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          width: "auto",
        }}
      >
        <div style={{ width: "100%", height: "100%",display: "flex",flexDirection:"row",
            justifyContent:'center', alignItems:"center" }}>

        <div style={{width:"90px",height:"90px"}}>
          <img
            src={Images.logoImg}
            alt="logo"
            style={{width:"100%",height:"100%"}}
          />
        </div>
        </div>
        <h1
          style={{
            alignSelf: "center" /* EMPLOYEE MANAGEMENT SYSTEM */,

            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "36px",
            lineHeight: "44px",
            textAlign: "center",

            color: "#7A1CAC",
          }}
        >
          EMPLOYEE MANAGEMENT <br /> SYSTEM
        </h1>
      </div>
      <div
        className="logincontainer "
        style={{
          // height: '430px',
          padding: "20px",
          gap: "0px",
          backgroundColor: "#EBD3F8",
          width:"450px",
        // height:"300px"
        }}
      >
        <form>
          <label></label>
          <input
            type="email"
            placeholder="Email"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "40px",
              top: "85px",
              gap: "0px",
              opacity: "0px",radius:"5px",border:"1.5px"
            }}
          ></input>
          <label></label>
          <input
            type="password"
            placeholder="Password"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "40px",
              top: "85px",
              gap: "0px",
              opacity: "0px",
            }}
          ></input>
         <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",}}>

          <div style={{display:'flex',justifyContent:'flex-start', marginTop:"20px",
            // width: "132px",
            height: "26px",
            fontStyle: 'normal',
            fontSize: "18px",
            alignItems: "center",
            color:" #777777",}}>
          <input type="checkbox" id="ball" name="ball" value="remember " style={{display:'flex',justifyContent:'flex-start', marginRight:"10px",
            // width: "132px",
            height: "26px",
            fontStyle: 'normal',
            fontSize: "12px",
            alignItems: "center",
            color:"tran",

    
            
            }} />
          <label
            for="ball" style={{display:'flex',justifyContent:'flex-start', marginRight:"10px",
                // width: "132px",
                height: "26px",
                fontStyle: 'normal',
                fontSize: "18px",
                alignItems: "center",
                color:" #777777",
    
                // width: "162px",
                
                top: "14px"}}>
            Remember Me
          </label>
          </div>
          <button
            style={{
             
 
             marginTop: "20px",
            background: "#7A1CAC",
              borderRadius: "5px",
            color:"white",
            outline:"none",
            border:"none",
            padding:"10px 40px 10px 40px"



              
            }}
            onClick={()=>navigation("/newemployee")}
          >
            Login
          </button>
         </div>
        </form>
      </div>
    </div>
  );
}
