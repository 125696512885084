



import React from 'react'

export default function NewQuotationForm() {
  return (
    <div className='formbg'>
       
        <form className='formful'>
            <div style={{display:'flex',flexDirection:"row"}}>
                <div style={{display:'flex',flexDirection:"row", flexwrap:"wrap",justifycontent:"center",gap:"30px"}}>
                
            </div>
            <div style={{display:'flex',flexdirection:"column",flexwrap:"wrap",justifyContent:"center",gap:"30px"}}>
            </div>
        <div>
            <label>CustomerId</label>
            <input type="text"placeholder="text"></input>
            <label>To Customername</label>
            <input type="text"placeholder="name"></input>
            </div>
        <div>

        </div>
        </div>


        </form>
    </div>
  )
} 
