import logo from './logo.svg';
import './App.css';
import Router from './Common/navigation/Router';

function App() {
  return (
   <Router/>
  );
}

export default App;
