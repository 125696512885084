import React from "react";
import Sidebar from "../Common/component/Sidebar";
import Navbar from "../Common/component/Navbar";
import AddSalesForm from "../Common/component/AddSales/AddSales";
import AddSaleForm from "../Common/component/AddSaleForm/AddSaleForm";

export default function AddSalesView() {
  return (
    <section>
    <div className="container-fluid" style={{overflow:"hidden",height:'100vh'}}>
      <div className="row p-0">
        <div className="col-lg-2 p-0">
      
          <Sidebar/>
        </div>
        <div className="col-lg-10  p-0">
          <div className="row ">
            <div className="col-lg-12 p-0">
              <Navbar/>

              
            </div>
            <div className="col-lg-12 ">
                {/* <EmployecreationForm/> */}
                {/* <AddSales/> */}
                 <AddSaleForm/> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    // <section>
    //   <div className="container-fluid" style={{ overflow: "hidden",height:'100vh' }}>
    //     <div className="row p-0">
    //       <div className="col-lg-2 p-0">
    //         <Sidebar />
    //       </div>
    //       <div className="col-lg-10  p-0">
    //         <div className="row ">
    //           <div className="col-lg-12 p-0">
    //             <Navbar />
    //           </div>
    //           <div className="col-lg-12 p-0 ">
    //             {/* <AddSalesForm /> */}
    //             <AddSaleForm/>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
}
