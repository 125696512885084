import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

export default function CustomerCreationForm() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const onSubmit = (date) => {
    console.log("dat_forms", date);
  };

  return (
    <div className="customercreationbg">
      {/* <h1>CustomerCreationForm</h1> */}

      {/* <div className='customercreationheader'> */}
      {/* <form  className='customercreationform' onSubmit={handleSubmit((data) => console.log(data))}>
      <input {...register('firstName')} />
      <input {...register('lastName', { required: true })} />
      {errors.lastName && <p>Last name is required.</p>}
      <input {...register('age', { pattern: /\d+/ })} />
      {errors.age && <p>Please enter number for age.</p>}
      <input type="submit" />
    </form> */}

      <div className="customercreationFormheader">
        <form
          className="customercreationform"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="customercontainer">
            <div className="customercreationheader">
              <div>
                <label className="custlabel">Customer Id</label>
                <input
                  className="custinput"
                  type="text"
                  {...register("customerId")}
                ></input>
              </div>
              <div>
                <label className="custlabel">Company Name</label>
                <input
                  type="text"
                  className="custinput"
                  {...register("companyname")}
                ></input>
              </div>
              <div>
                <label className="custlabel">Phone Number</label>
                <input
                  type="text"
                  className="custinput"
                  {...register("phonenumber", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                    },
                  })}
                ></input>
                {isSubmitted && errors.phonenumber && (
                  <p>Phone number must be exactly 10 digits</p>
                )}
              </div>

              <div>
                <label className="custlabel">Date of Enquiry</label>
                <DatePicker
                  wrapperClassName="custdate"
                  className="custdate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  // style={{ display: "flex", flexDirection: "column" ,height:'50px',width:'400px',background:" #FFF6EA",}}
                />
              </div>
              <div>
                <label className="custlabel">Need</label>
                <input
                  type="text"
                  
                  className="custinput"
                ></input>
                {/* <select value={selectedOption} onChange={(handleSelectChange)}></select> */}
                {/* <select  className="custselect"></select> */}
                {/* <input
                type="text"
                style={{ display: "flex", flexDirection: "column" }}
              ></input> */}

                <label className="custlabel"></label>
                <input
                  type="text"
                  placeholder="*non mandatory"
                  className="custominput"
                ></input>
              </div>
              <div>
                <label className="custlabel">Description</label>
                <input
                  type="text"
                  className="customdescription"
                  {...register("description")}
                 
                ></input>
              </div>
            </div>
            <div className="customercreationheaderright">
              <div>
                <label className="custlabel">Client Name</label>
                <input
                  type="text"
                  className="custinput"
                  {...register("clientname")}
                ></input>
              </div>
              <div>
                {/* <label style={{width: '400px',

// top: '112.83px',
gap: '5px',
opacity: '0px'
}}>Designation</label> */}
                <label className="custlabel">Designation</label>
                <input
                  type="text"
                  className="custinput"
                  {...register("designation")}
                ></input>
              </div>
              <div className="customselectright">
                <label className="custlabel">Base</label>
                <input
                  type="text"
                  
                  className="custinput"
                ></input>
                {/* <select  className="custselect"></select> */}
                {/* <select value={selectedOption} onChange={(handleSelectChange)}></select> */}
                {/* <input
                type="text"
                style={{ display: "flex", flexDirection: "column" }}
              ></input> */}
                <input
                  type="text"
                  placeholder="*non mandatory"
                  className="custominput"
                ></input>
              </div>

              <div>
                <label className="custlabel">Tele Caller Name</label>
                <input
                  type="text"
                  className="custinput"
                  {...register("telecallername")}
                ></input>
              </div>

              <div className="custlable">
                <label className="custlabel">Tele Caller Mobile NO</label>
                <input
                  type="text"
                  className="custinput"
                  {...register("telecallermobileno", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                    },
                  })}
                ></input>
                {isSubmitted && errors.telecallermobileno && (
                  <p>Phone number must be exactly 10 digits</p>
                )}
              </div>
              <div>
                <label className="custlabel">Assign To</label>
                {/* <select value={selectedOption} onChange={(handleSelectChange)}></select> */}
                {/* <select  className="custselect"></select> */}
                {/* <input
                type="number"
                style={{ display: "flex", flexDirection: "column" }}
              ></input> */}
              <input
                  type="text"
                  
                  className="custinput"
                ></input>
              </div>

              <div className="btn-group dropend">
                <button type="submit" className="btn btn-primary btn-custom ">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
