import React from "react";
import LoginForm from "../Common/component/LoginForm";

export default function login() {
  return (
    <section>
      <div className="container">
        <div className="row align-items-center vh-100 justify-content-center">
          <div className="col-lg-12 align-self-center d-flex justify-content-center">
            <LoginForm />
          </div>
        </div>
      </div>
    </section>
  );
}
