import React from "react";
import { useForm } from "react-hook-form";
export default function StudentCreationForm() {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const onSubmit = (deepa) => {
    console.log("form_deepa", deepa);
  };

  return (
    <div className="student">
      {/* <h1>heloo</h1> */}
      <form className="stuform" onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "center",
            gap: "200px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "15px",
              gap: "15px",
            }}
          >
            <label className="stulabel">StudentName</label>

            <input
              type="text"
              placeholder="Deepa"
              className="stuinput"
              {...register("studentName")}
            />

            <label className="stulabel"> CollegeName/company name</label>
            <input
              type="text"
              placeholder="Anand Instiute of Higher Technology"
              className="stuinput"
              {...register("collegeName")}
            />

            <label className="stulabel">phone number</label>
            <input
              type="text"
              placeholder="7397356487"
              className="stuinput"
              {...register("phoneNumber", {
                required: "phone number is required",
                pattern: {
                  // value: /^[0-9]{10}$/,
                  message: "invalid",
                },
              })}
            />
            {errors.phoneNumber && <p>Phone invalid </p>}
            <label className="stulabel">Date of Birth</label>
            <input
              type="text"
              placeholder="30-12-2002"
              className="stuinput"
              {...register("dateofbirth")}
            />
            <label className="stulabel"> Flat No</label>
            <input
              type="text"
              placeholder="NO:8"
              className="stuinput"
              {...register("flatNo")}
            />
            <label className="stulabel"> Area/Village </label>
            <input
              type="text"
              placeholder="Thiruppour"
              className="stuinput"
              {...register("area")}
            />
            <label className="stulabel">pincode </label>
            <input
              type="text"
              placeholder="603110"
              className="stuinput"
              {...register("pincode")}
            />
            <label className="stulabel"> Date of end</label>
            <input
              type="text"
              placeholder="DD/MM/YYYY"
              className="stuinput"
              {...register("dateOfEnd")}
            />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "15px",
                gap: "15px",
              }}
            >
              <label className="stulabel">Registernumber</label>
              <input
                type="text"
                placeholder="310121104020"
                className="stuinput"
                {...register("registernumber")}
              />
              <label className="stulabel">Course</label>
              <input
                type="text"
                placeholder="React js"
                className="stuinput"
                {...register("course")}
              />
              <label className="stulabel">Emergency number</label>
              <input
                type="text"
                placeholder="8682894103"
                className="stuinput"
                {...register("emergencyNo")}
              />
              <label className="stulabel"> Aadhaar number</label>
              <input
                type="text"
                placeholder="7808 6561 7310"
                className="stuinput"
                {...register("aadhaarNumber")}
              />
              <label className="stulabel">street</label>
              <input
                type="text"
                placeholder="kacheri lane street"
                className="stuinput"
                {...register("street")}
              />
              <label className="stulabel">city</label>
              <input
                type="text"
                placeholder="chennai"
                className="stuinput"
                {...register("city")}
              />
              <label className="stulabel"> Date of joining </label>
              <input
                type="text"
                {...register("dateOfJoin")}
                placeholder="22-07-2024"
                className="stuinput"
              />
              <div
                className="stusubmit"
                onClick={() => {
                  console.log("hello");
                }}
              >
                
              </div>
            </div>
          <button className="stsubmit" type="submit">
                  {" "}
                  submit
                </button>
          </div>
        </div>
      </form>
    </div>
  );
}
