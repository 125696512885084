// import axios from 'axios';
import React, { useEffect, useState } from "react";
// import { useQuery } from 'react-query';
// import { useNavigate } from "react-router-dom";
// import * as XLSX from "xlsx";
// import Images from '../../asset';
import { useTable } from "react-table";
import Images from "../../../asstes";
export default function ViewCustomerTable() {
  const [searchTerm, setSearchTerm] = useState("");
  const columns = React.useMemo(
    () => [
      { Header: "Select", accessor: "select" },
      { Header: "Sno", accessor: "sno" },
      { Header: "Customer Id", accessor: "customerId" },
      { Header: "Customer Name", accessor: "customerName" },
      { Header: "Client Name", accessor: "clientName" },
      { Header: "Phone Number", accessor: "phoneNo" },
      { Header: "Designation", accessor: "designation" },
      { Header: "Date of Enquiry", accessor: "dateofEnquiry" },
      { Header: "Tele Calling Base", accessor: "teleCallingBase" },
      { Header: "Mobile Number", accessor: "mobileNumber" },
      { Header: "Need", accessor: "need" },
      { Header: "Description", accessor: "description" },
      { Header: "Tele Caller Name", accessor: "teleCallerName" },
      // { Header: "Mobile Number", accessor: "mobileNumber" },
      { Header: "Assign From", accessor: "assignFrom" },
      { Header: "Assign To", accessor: "assignTo" },
      { Header: "Pending From", accessor: "pendingFrom" },
    ],
    []
  );
  const tabelcolums = React.useMemo(
    () =>[
    {
      select:<input type="checkbox"/>,
      sno: 1,
      customerId: "2K001",
      customerName: "Hello",
      clientName: "2K Virtualworld",
      phoneNo: 7358437087,
      designation: "Developer",
      dateofEnquiry: "10/10/2024",
      teleCallingBase: "TamilNadu",
      need: "Mobile Application",
      description: "need a creative mobile application",
      teleCallerName: "Sanjay",
      mobileNumber: 1234567890,
      assignFrom: "Kannan",
      assignTo: "Anu",
      pendingFrom: "Level-1",
    },
    {
      select:<input type="checkbox"/>,
      sno: 2,
      customerId: "2K002",
      customerName: "Bye",
      clientName: "2K Virtualworld",
      phoneNo: 7358437087,
      designation: "Developer",
      dateofEnquiry: "10/10/2024",
      teleCallingBase: "TamilNadu",
      need: "Mobile Application",
      description: "need a creative mobile application",
      teleCallerName: "Sanjay",
      mobileNumber: 1234567890,
      assignFrom: "Kannan",
      assignTo: "Anu",
      pendingFrom: "Level-1",
    },
    {
      select:<input type="checkbox"/>,
      sno: 3,
      customerId: "2K003",
      customerName: "Ram",
      clientName: "2K Virtualworld",
      phoneNo: 7358437087,
      designation: "Developer",
      dateofEnquiry: "10/10/2024",
      teleCallingBase: "TamilNadu",
      need: "Mobile Application",
      description: "need a creative mobile application",
      teleCallerName: "Sanjay",
      mobileNumber: 1234567890,
      assignFrom: "Kannan",
      assignTo: "Anu",
      pendingFrom: "Level-1",
    },
    {
      select:<input type="checkbox"/>,
      sno: 4,
      customerId: "2K004",
      customerName: "John",
      clientName: "2K Virtualworld",
      phoneNo: 7358437087,
      designation: "Developer",
      dateofEnquiry: "10/10/2024",
      teleCallingBase: "TamilNadu",
      need: "Mobile Application",
      description: "need a creative mobile application",
      teleCallerName: "Sanjay",
      mobileNumber: 1234567890,
      assignFrom: "Kannan",
      assignTo: "Anu",
      pendingFrom: "Level-1",
    },
  ],[]);
  const filteredData = tabelcolums.filter(item =>
    item.customerId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.customerName.toLowerCase().includes(searchTerm.toLowerCase()) 
   
  );
  const tableInstance = useTable({ columns, data: filteredData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  tableInstance;
  return (
    <div className="customerviewtablebg" style={{ overflowY: "hidden" }}>
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center",padding:"0px 20px"}}>

          <div className="customerviewsearch" style={{display:"flex",alignItems:"center",background:"#fff",width:"20%",margin:"15px 25px",height:"35px",borderRadius:"5px",justifyContent:"space-between",padding:"0px 10px",border:'2px solid #7a1cac'}}>
        <input type="text" className=" customerviewtableinput" onChange={(e) => setSearchTerm(e.target.value)} style={{border:"none",outline:"none",borderRadius:"0px",height:"20PX"}}></input>
        <div style={{width:'22px', height:'22px'}}>

        <img src={Images.searchIcon} alt="searchIcon" style={{width:"100%",height:"100%"}}/>
        </div>
      </div>
        <div>
          <div style={{width:"40px",height:"40px"}}>

        <img src={Images.downloadIcon} width="100%" height="100%" alt="download"/>
          </div>
        </div>
      </div>

      <div className="customerviewincontainer">
             <table
          cellPadding={"10px"}
          cellSpacing={"0px"}
          className="customerviewtable"
        >
          <thead>
            {
              headerGroups.map((headerGroup) => (

            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map((column) => (

                  <th className="viewcustomerheader"  {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))
              }
             
            </tr>
              ))
            }
          </thead>
          <tbody>
          {rows.map(row => {
           prepareRow(row)
           return (
               <tr {...row.getRowProps()}>
                 {row.cells.map(cell => {
                   return (
                       <td
                           {...cell.getCellProps()}
                         className="viewcustomerdata"
                       >
                         {cell.render('Cell')}
                       </td>
                   )
                 })}
               </tr>
           )
         })}
         
          </tbody>
        </table>
      </div>
    </div>
  );
}
