import React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import DatePicker from "react-datepicker";

export default function EmployeeCreationform() {
  const [startDate, setStartDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors,isSubmitted
     },
    control
  } = useForm()
  const{employeeID}=watch()
  console.log("watch",employeeID)
  const onSubmit=(date)=>{
console.log("dat_form",date)
  }
  

  
  return (
    <div className="EmployeeCreationformbg">
      <form className="EmployeeForm mt-5 pt-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="empform" >
    
       
          <div className="empformleft">
            <label className="empl">Employee ID</label>
            <input type="text" placeholder="employee id" className="inputeid" {...register("employeeID")} />
            
            
            <label className="empl">Role</label>
            <input
              type="text"
              placeholder=" Eg:front end developer"
              className="inputeid" {...register("role")}
            />

            <label className="empl">Phone no</label>

            <input type="text" placeholder="Phoneno" className="inputeid" {...register("phoneNo", { required: true, minLength: 10, maxLength: 10 }) }/>
            {isSubmitted &&  errors.phoneNo && <p> Invalid Phone Number </p>}

            <label className="empl">Email id</label>

            <input type="text" placeholder="Email id" className="inputeid" {...register('email', {
          required: 'Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            
        
          },
        })} 
      />
      {isSubmitted && errors.email && <p>Invalid  email please enter the valid email </p>}
            <label className="empl">Date of Birth</label>
            <input type="text" placeholder="dd-mm-yyyy" className="inputeid" />

            <label className="empl">Street</label>
            <input type="text" placeholder="Street name" className="inputeid" {...register("street")}/>
            <label className="empl">City</label>
            <input type="text" placeholder="City Name" className="inputeid" {...register("city")}/>
            <label className="empl">Bank name</label>
            <input type="text" placeholder="Bank Name" className="inputeid"  {...register("bank")}/>
            <label className="emp1">IFSC CODE</label>

            <input type="text" placeholder="IFSC CODE" className="inputeid" {...register("ifsc")}/>
            <label className="empl">Date of joining</label>

            <DatePicker 
              // {...register("dateOfJoin")}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="inputeid"
            />
          </div>
          <div  className="empformright">
           
            <label className="empl">Employeename</label>
            <input type="text" placeholder="name" className="inputeid" {...register("employename")} />
            <label className="empl">Description</label>
            <input type="text" placeholder="Developer" className="inputeid" {...register("description")}/>
            <label className="empl"> Aadharno</label>
            <input type="text" placeholder="phno" className="inputeid" {...register("aadharno")}/>
            <label className="empl"> Alternate no</label>
            <input
              type="text"
              placeholder="Alternate no"
              className="inputeid"
              {...register("alternateNo")}/>
            <label className="empl">Flatno</label>
            <input type="text" placeholder="flatno" className="inputeid" {...register("flatNo")}/>
            <label className="empl">pincode</label>
            <input type="text" placeholder="pincode" className="inputeid" {...register("pincode" ,{required:true,pattern:{value: /^[0-9]{4,6}$/,},})} />
            {isSubmitted && errors.pincode && <p>Invalid pincode</p>}
            <label className="empl">Area</label>
            <input type="text" placeholder="Area" className="inputeid" {...register("area")}/>
            <label className="empl">Bank account number</label>
            <input
              type="text"
              placeholder="account number"
              className="inputeid"
              {...register("accountNumber")}
            />
            <label className="empl">Date of reliving</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="inputeid"
            />
            <div className="empsub">
              <button className="empsubbutton">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
