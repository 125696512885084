import React from "react";
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Login from "../../Page/login";
// import AddSales from "../../Page/AddSales";
import StudentFormPg from "../../Page/StudentFormPg";
// import StudentFormPg from "../../Page/StudentCreation";
// import  StudentcreationForm from "../../Page/StudentcreationForm";
//import studentcreationForm from "../component/EmployecreationForm/EmployecreationForm";
import Employeecreation from "../../Page/Employeecreation";
// import AddSales from "../../Page/AddSales";
import NewQuotation from "../../Page/NewQuotation";
import UploadPayslip from "../../Page/Uploadpayslip";
import Downloadpayslip from "../../Page/Downloadpayslip";
//no need to import component in the router we need to import the page
import CustomerCreation from "../../Page/Customercreation";
import Viewemployee from "../../Page/Viewemployee";
// import Viewcustomer from "../../Page/Viewcustomer";
import Viewcustomertable from "../../Page/Viewcustomertable"
// import Viewemployee from "../../Page/Viewemployeefrom";
// import Viewcustomer from "../../Page/Viewcustomer";
// import Viewemployee from "../../Page/Viewemployee";
import ViewemployeeTable from "../../Page/ViewemployeeTable";
import AddSalesView from "../../Page/AddSales";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/StudentFormPg" element={<StudentFormPg />} />
      <Route path="/AddSales" element={<AddSalesView />} />
      {/* <Route path="/" element={<StudentcreationForm />} /> */}
      {/* <Route path="/"element={<StudentcreationForm />} /> */}
       {/* <Route path="/StudentCreationForm" element={<StudentcreationForm />} */}
      <Route path="/" element={<Login />} />
      {/* <Route path="/login" element={<About />} /> */}
      {/* <Route path="/about" element={<About />} /> */}
      <Route path="/NewQuotation" element={<NewQuotation />} />
      {/* <Route path="/" element={<Employeecreation />} /> */}
      <Route path="/UploadPayslip" element={<UploadPayslip />} />
      <Route path="/Downloadpayslip" element={<Downloadpayslip/>}/>
      
   
      {/* <Route path="/" element={<Employeecreation />} /> */}
      {/* <Route path="/employeecreation" element={<CustomerCreationForm />} /> */}
      <Route path="/AddCustomer" element={<CustomerCreation />} />
      {/* <Route path="/ViewCustomer" element={<ViewCustomer />} /> */}
      <Route path="/ViewCustomer" element={<Viewcustomertable />} />

      <Route path="/newemployee" element={<Employeecreation />} />
      <Route path="/viewemployee" element={<Viewemployee/>} />
      <Route path="/viewemployeeTable" element={<ViewemployeeTable/>} />
      </Routes>
    </BrowserRouter>
  );
}
