import React from "react";

export default function Uploadpayslipcom() {
  return (
    <div
      className="Uploadpayslipbg"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        
      }}
    >
      <div
        className="Uploadform"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf:"center",
       
        }}
      >
        <h6
          style={{
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "17px",
            color: "#7A1CAC",
            paddingRight: "230px",
          }}
        >
          Upload File
        </h6>

        <input
          type="file"
          placeholder=""
          style={{
            border: "2px solid #7A1CAC",
            padding: "10px 10px",
            borderRadius: "5px",
            background: "#fff",
          }}
        ></input>

        <div className="pt-2">
          <button
            style={{
              /* Login */

              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              padding: "10px ",
              paddingLeft: "40px",
              paddingRight: "40px",
              fontSize: "18px",
              lineHeight: "22px",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              background: "#7A1CAC",
              color: "#FFF6EA",
              borderRadius: "9px",
              outline: "none",
              border: "none",
              // paddingLeft:"30px"
            }}
          >
            submit
          </button>
        </div>
      </div>
    </div>
  );
}
