import React from "react";
import notification from "./png/notification.png";
import logout from "./png/logout.png";
import logo from "./png/logo.png";
import search from "./png/search.png"
import download from "./png/download.png"
const Images = {
  logoImg: logo,
  notificationIcon: notification,
  logoutIcon: logout,
  searchIcon:search,
  downloadIcon:download
};
export default Images;
