import React from 'react'

export default function ViewemployeeformPg() {
  return (
    <div className='viewempform'>
       <div className='searchemp'>
        <input type="search" placeholder='' className='searchbar'/>
        </div>
        <div className='viewhead'>
          <table cellPadding='15px' style={{marginLeft:'10px',marginTop:'20px',backgroundColor:'blueviolet'}}>
            <thead>
              <tr>
            <th>
              S.NO
            </th>
            <th>employeeID</th>

            </tr>
            </thead>

          </table>
         
         
         
        </div>
    </div>
   
  )
}
