import React from 'react'


export default function Downloadpayslipcom() {
  return (
    <div className="Downloadpayslipbg">
        <div className='Downloadform'>
            <h6> DOWNLOAD PAYSLIP</h6>
            <div className='Form'>
            <label className="Emplabel"> Employee ID </label>
            <input type='text' placeholder=''></input>
            <label className="Emplabel"> Select Month</label>
            <input type='month' placeholder=''></input>
            <label className="Emplabel"> Password</label>
            <input type ='password' placeholder=''></input>
            </div>
        </div>
        <div style= {{marginBottom:'2px'}}>  
        <button>Download</button>
           </div>
            </div>

        
         
      
    
  );
}
